<template>  
  <v-footer>
    <v-row justify="center" no-gutters>
      <v-btn href="https://atania.kleostechnologies.com/dss/" color="#5e5d5d" text>
          Map
      </v-btn>
      <v-btn @click="dialogAboutUs = true" color="#5e5d5d" text>
          About Us
      </v-btn>
      <v-btn @click="dialogContactUs = true" color="#5e5d5d" text>
          Contact Us
      </v-btn>
    </v-row>

    <v-dialog v-model="dialogAboutUs" :width="$vuetify.breakpoint.lgAndUp? '60%' : '90%'" :height="$vuetify.breakpoint.lgAndUp? '50%' : '80%'">
      <v-card dense>
        <v-card-title class="justify-center">
          About Us 
        </v-card-title>
        <v-divider></v-divider>
        <v-container>
            <v-card-text>
                <p>
                  The application DSS in agriculture and environment has been rapidly increased in the past decade, 
                  which allows rapid assessment of agricultural production systems around the world and 
                  decision-making at both farm and district levels, 
                  though constraints exist for successful adoption of this technology in agriculture.
                </p>
                <p>
                  A decision support system (DSS) is an interactive software-based system used to help decision-makers 
                  compile useful information from a combination of raw data, documents, and personal knowledge; 
                  to identify and solve problems; and to make an optimized decision. 
                  The DSS architecture consists of the database (or knowledge base), 
                  the model (i.e., the decision context and user criteria), and the user interface.
                </p>
            </v-card-text>

        </v-container>

        <v-divider></v-divider>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialogAboutUs = false">
                Close
            </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogContactUs" :width="$vuetify.breakpoint.lgAndUp? '60%' : '90%'">

      <v-card > 
        <v-container class="px-3 py-0">

          <v-row>
            <v-col :cols="$vuetify.breakpoint.lgAndUp? '8' : '12'">

              <div class="mapouter">
                  <div class="gmap_canvas">
                      <iframe height="500" style="width: 100%;" id="gmap_canvas"
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4080007.7699742396!2d97.21970962500001!3d2.991158499999993!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cdb58110d225b7%3A0x28a3664109cebe04!2sInstitut%20Penyelidikan%20Dan%20Kemajuan%20Pertanian%20Malaysia%20(MARDI)!5e0!3m2!1sen!2sus!4v1672765733191!5m2!1sen!2sus&t=&z=13&ie=UTF8&iwloc=&output=embed"
                          frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                      <a href="https://123movies-to.org"></a>
                  </div>
              </div>

            </v-col>

            <v-col class="align-center">
              <v-divider></v-divider>
              <v-card-text :class="$vuetify.breakpoint.lgAndUp? 'contactUsLarge' : ''">
                  Sistem Pakar Pertanian <br>
                  Malaysian Argricultural Research and Development Institute , <br>
                  Persiaran MARDI-UPM, <br>
                  43400 Serdang, <br>
                  Selangor Darul Ehsan.

                  <br><br>

                  <v-icon class="mr-2">mdi-phone</v-icon> Tel: +603 8953 6000 <br>
                  <v-icon class="mr-2">mdi-email</v-icon> Email: enquiryspp@mardi.gov.my <br>
              </v-card-text>

              <!-- <v-divider></v-divider>

              <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="" text @click="dialogContactUs = false">
                      Close
                  </v-btn>
              </v-card-actions> -->

            </v-col>

          </v-row>
        </v-container>


      </v-card>

      </v-dialog>



  </v-footer>
</template>

<script>
  export default {
    data: () => ({
      dialogAboutUs: false,
      dialogContactUs : false,
    }),
  }
</script>

<style lang="scss" scoped>
@import '~scss/main';

.mapouter{position:relative;text-align:right;height:100%;width:100%;}

.gmap_canvas {overflow:hidden;background:none!important;height:100%;width:100%;}

</style>