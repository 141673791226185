<template>
    <v-container
        fluid
        class="d-flex flex-row align-start pa-0 align-stretch"
        
    >
        <v-navigation-drawer
            absolute
            permanent
            app
            style="top:75px !important; height: calc(100% - 75px) !important;"
        >
            <v-list
                nav
                dense
                v-for="list in navList"
                :key="list.text"
            >
            <v-list-item link v-on:click="goToPage(list.link)" :class="$route.name == list.text ? 'page-active' : ''">
                <v-list-item-icon>
                <v-icon>{{ list.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ list.text }}</v-list-item-title>
            </v-list-item>
            </v-list>
        </v-navigation-drawer>
    </v-container>
  </template>

  <script>
  // -- import something here --
  
  export default {
  
    data: () => ({
      navList:[
        // {
        //     text: 'Map',
        //     icon: 'mdi-map-outline',
        //     link: '/Map'
        // },
        {
            text: 'Crop',
            icon: 'mdi-tree-outline',
            link: '/Crop'
        },
        // {
        //     text: 'Crop Group',
        //     icon: 'mdi-fruit-grapes-outline',
        //     link: '/CropGroup'
        // },
        {
            text: 'Crop Price',
            icon: 'mdi-cash',
            link: '/CropPrice'
        },
        {
            text: 'User Management',
            icon: 'mdi-card-account-details-outline',
            link: '/User'
        },
        {
            text: 'Audit Trail',
            icon: 'mdi-clipboard-search-outline',
            link: '/Audit'
        },
      ]
    }),
  
    mounted() {
      
    },
  
    methods: {
    },
  
  };
  </script>
  
  <style lang="scss">
  @import '~scss/main';
  .background-header{
    background-color: #25274D !important;
  }

  .page-active {
    background-color: #f2f5f3 !important;
  }

  </style>
  
  
  