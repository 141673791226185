import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: '#25274D',
                secondary: '#29648A',
                base: '#ededed',

                // success: '#107079',
                // error: '#ff3737',
            },
            dark: {},
        },
    },
    icons: {
        iconfont: 'mdi',
    },

});
