<template>
  <div>
    <v-app-bar
      color="white"
      height="72"
    >
      <v-img
        class="mr-2"
        src="@/assets/img/mardi-logo.png"
        max-height="100"
        max-width="31"
      ></v-img>
      <div v-if="$vuetify.breakpoint.mdAndUp">
        <v-toolbar-title class="font-weight-bold">Malaysian Agricultural Research and Development Institute</v-toolbar-title>  
      </div>
      <div v-else>
        <v-toolbar-title class="font-weight-bold">MARDI</v-toolbar-title>  
      </div>
      <v-spacer></v-spacer>
      <div v-if="$vuetify.breakpoint.mdAndUp">
        <v-btn 
          href="https://atania.app/dss/"
          outlined
          class="mr-2"
        >
          <v-icon left>mdi-map-outline</v-icon>
          Map
        </v-btn>

        <v-btn 
          outlined
          class="mr-2"
          @click="dialogAboutUs = true"
        >
          <v-icon left>mdi-information-outline</v-icon>
          About Us
        </v-btn>

        <v-btn 
          outlined
          @click="dialogContactUs = true"
        >
          <v-icon left>mdi-cellphone-marker</v-icon>
          Contact Us
        </v-btn>
      </div>
      <div v-else>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-view-headline</v-icon>
            </v-btn>
          </template>
          <v-list nav>
            <v-list-item link>
              <v-list-item-title @click="displayMap()"><v-icon class="mr-2">mdi-map-outline</v-icon>Map</v-list-item-title>
            </v-list-item>
            <v-list-item link>
              <v-list-item-title @click="dialogAboutUs = true"><v-icon class="mr-2">mdi-information-outline</v-icon>About Us</v-list-item-title>
            </v-list-item>
            <v-list-item link>
              <v-list-item-title @click="dialogContactUs = true"><v-icon class="mr-2">mdi-cellphone-marker</v-icon>Contact Us</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
      
    </v-app-bar>

    <v-dialog v-model="dialogAboutUs" :width="$vuetify.breakpoint.lgAndUp? '60%' : '90%'" :height="$vuetify.breakpoint.lgAndUp? '50%' : '80%'">
      <v-card dense>
        <v-card-title class="justify-center headline">
          About Us 
        </v-card-title>
        <v-divider></v-divider>
        <v-container>
            <v-card-text>
                <p>
                  The application DSS in agriculture and environment has been rapidly increased in the past decade, 
                  which allows rapid assessment of agricultural production systems around the world and 
                  decision-making at both farm and district levels, 
                  though constraints exist for successful adoption of this technology in agriculture.
                </p>
                <p>
                  A decision support system (DSS) is an interactive software-based system used to help decision-makers 
                  compile useful information from a combination of raw data, documents, and personal knowledge; 
                  to identify and solve problems; and to make an optimized decision. 
                  The DSS architecture consists of the database (or knowledge base), 
                  the model (i.e., the decision context and user criteria), and the user interface.
                </p>
            </v-card-text>

        </v-container>

        <v-divider></v-divider>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialogAboutUs = false">
                Close
            </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogContactUs" :width="$vuetify.breakpoint.lgAndUp? '60%' : '90%'" :height="$vuetify.breakpoint.lgAndUp? '50%' : '80%'">
      <v-card dense>
        <v-card-title class="justify-center headline">
          Contact Us 
        </v-card-title>
        <v-divider></v-divider>
        <v-container>
          <v-row>
            <v-col cols="12" sm="8">
              <div class="mapouter">
                  <div class="gmap_canvas">
                      <iframe height="500" style="width: 100%;" id="gmap_canvas"
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4080007.7699742396!2d97.21970962500001!3d2.991158499999993!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cdb58110d225b7%3A0x28a3664109cebe04!2sInstitut%20Penyelidikan%20Dan%20Kemajuan%20Pertanian%20Malaysia%20(MARDI)!5e0!3m2!1sen!2sus!4v1672765733191!5m2!1sen!2sus&t=&z=13&ie=UTF8&iwloc=&output=embed"
                          frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                      <a href="https://123movies-to.org"></a>
                  </div>
              </div>
            </v-col>
            <v-col cols="12" sm="4">
              Sistem Pakar Pertanian <br>
              Malaysian Argricultural Research and Development Institute , <br>
              Persiaran MARDI-UPM, <br>
              43400 Serdang, <br>
              Selangor Darul Ehsan.

              <br><br>

              <v-icon class="mr-2">mdi-phone</v-icon> Tel: +603 8953 6000 <br>
              <v-icon class="mr-2">mdi-email</v-icon> Email: enquiryspp@mardi.gov.my <br>
            </v-col>
          </v-row>
          

        </v-container>

        <v-divider></v-divider>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialogContactUs = false">
                Close
            </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- <v-dialog v-model="dialogContactUs" :width="$vuetify.breakpoint.lgAndUp? '60%' : '90%'">

      <v-card > 
        <v-container class="px-3 py-0">

          <v-row>
            <v-col :cols="$vuetify.breakpoint.lgAndUp? '8' : '12'">

              <div class="mapouter">
                  <div class="gmap_canvas">
                      <iframe height="500" style="width: 100%;" id="gmap_canvas"
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4080007.7699742396!2d97.21970962500001!3d2.991158499999993!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cdb58110d225b7%3A0x28a3664109cebe04!2sInstitut%20Penyelidikan%20Dan%20Kemajuan%20Pertanian%20Malaysia%20(MARDI)!5e0!3m2!1sen!2sus!4v1672765733191!5m2!1sen!2sus&t=&z=13&ie=UTF8&iwloc=&output=embed"
                          frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                      <a href="https://123movies-to.org"></a>
                  </div>
              </div>

            </v-col>

            <v-col class="align-center">
              <v-divider></v-divider>
              <v-card-text :class="$vuetify.breakpoint.lgAndUp? 'contactUsLarge' : ''">
                  Sistem Pakar Pertanian <br>
                  Malaysian Argricultural Research and Development Institute , <br>
                  Persiaran MARDI-UPM, <br>
                  43400 Serdang, <br>
                  Selangor Darul Ehsan.

                  <br><br>

                  <v-icon class="mr-2">mdi-phone</v-icon> Tel: +603 8953 6000 <br>
                  <v-icon class="mr-2">mdi-email</v-icon> Email: enquiryspp@mardi.gov.my <br>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="" text @click="dialogContactUs = false">
                      Close
                  </v-btn>
              </v-card-actions>

            </v-col>

          </v-row>
        </v-container>


      </v-card>

      </v-dialog> -->
  </div>
</template>

<script>
export default {
    data () {
      return {
        dialogAboutUs: false,
        dialogContactUs : false,
      }
    },

    mounted(){
      
    },

    methods: {

      loading() {
        return this.$store.getters.loading
      },

      signOut() {
        this.$store.dispatch('signOut')
      },

      displayMap(){
        //window.location.href = 'https://atania.app/dss/'
        window.open(
          'https://atania.app/dss/',
          '_blank' // <- This is what makes it open in a new window.
        )
      }
      
    },

    computed: {
      user() {
          return this.$store.getters.user
      },
    },
}
</script>

<style lang="scss" scoped>
@import '~scss/main';


.mapouter{position:relative;text-align:right;height:100%;width:100%;}

.gmap_canvas {overflow:hidden;background:none!important;height:100%;width:100%;}

.headline{
  color: #29648A;
  word-break: break-word;
}
</style>

