export default {
    data() {
        return {

        }
    },    
    mounted(){
        
    },
    methods: {

        goToPage(url){
            this.$router.push({ path: url }).catch(()=>{});
        },
        
    },
    computed: {

        // levelAdmin() {
        //     return this.$store.getters.userRole === 'admin' ? true : false
        // },

        // levelUser() {
        //     return this.$store.getters.userRole === 'user' ? true : false
        // },

    },
}