import Vue from "vue";
import VueRouter from "vue-router";
// import AuthGuard from './auth-guard';

Vue.use(VueRouter)

export default new VueRouter({
    mode: "history",
    routes: [
        
        // == Home ==
        { 
            path: "/",
            name: "Mainpage",
            component: () => import('@/views/Login'),
            meta: { showFooter: false, showLeftSidebar: false, showAppHeader: false, showMainHeader: true},
            
        },

        // == Login ==
        { 
            path: "/login",
            name: "Login",
            component: () => import('@/views/Login'),
            meta: { showFooter: false, showLeftSidebar: false, showAppHeader: false},
            
        },

        { 
            path: "/map",
            name: "Map",
            component: () => import('@/views/Map'),
            meta: { showFooter: false, showLeftSidebar: true, showAppHeader: true},
            
        },

        { 
            path: "/Crop",
            name: "Crop",
            component: () => import('@/views/Crop'),
            meta: { showFooter: false, showLeftSidebar: true, showAppHeader: true},
            
        },

        { 
            path: "/CropGroup",
            name: "Crop Group",
            component: () => import('@/views/Crop Group'),
            meta: { showFooter: false, showLeftSidebar: true, showAppHeader: true},
            
        },

        { 
            path: "/CropPrice",
            name: "Crop Price",
            component: () => import('@/views/Crop Price'),
            meta: { showFooter: false, showLeftSidebar: true, showAppHeader: true},
            
        },

        { 
            path: "/User",
            name: "User Management",
            component: () => import('@/views/User'),
            meta: { showFooter: false, showLeftSidebar: true, showAppHeader: true},
            
        },

        { 
            path: "/Audit",
            name: "Audit Trail",
            component: () => import('@/views/Audit Trail'),
            meta: { showFooter: false, showLeftSidebar: true, showAppHeader: true},
            
        },

    ]
});
