<template>
  <div>
    <v-app-bar
      color="white"
      height="72"
    >

      <v-list-item two-line  class="">
        <v-list-item-avatar>
            <img src="@/assets/img/mardi-logo.png">
        </v-list-item-avatar>

        <v-list-item-content>
            <v-list-item-title class="black--text font-weight-bold">Sistem Pakar Pertanian</v-list-item-title>
            <!-- <v-list-item-subtitle class="black--text"></v-list-item-subtitle> -->
        </v-list-item-content>
      </v-list-item>

      <v-spacer></v-spacer>
      

      <v-btn 
        class="mr-2"
        outlined 
        href="https://atania.app/dss/"
        target="_blank"
        v-if="$vuetify.breakpoint.mdAndUp"      
      >
        <v-icon>mdi-map-outline</v-icon>
        Map
      </v-btn>
      <v-btn 
        class="mr-2"
        color="red"
        outlined
        @click="goToPage('/')"
        v-if="$vuetify.breakpoint.mdAndUp"
      >
        <v-icon>mdi-logout-variant</v-icon>
        Logout
      </v-btn>

      <div v-else>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
            >
              <v-icon>mdi-view-headline</v-icon>
            </v-btn>
          </template>
          <v-list nav dense>
            <v-list-item link>
              <v-list-item-title @click="displayMap()"><v-icon class="mr-2">mdi-map-outline</v-icon>Map</v-list-item-title>
            </v-list-item>
            <v-list-item link :class="$route.name == 'Crop' ? 'page-active' : ''">
              <v-list-item-title @click="goToPage('/Crop')"><v-icon class="mr-2">mdi-tree-outline</v-icon>Crop</v-list-item-title>
            </v-list-item>
            <v-list-item link :class="$route.name.includes('Crop Price') ? 'page-active' : ''">
              <v-list-item-title @click="goToPage('/CropPrice')"><v-icon class="mr-2">mdi-cash</v-icon>Crop Price</v-list-item-title>
            </v-list-item>
            <v-list-item link :class="$route.name.includes('User') ? 'page-active' : ''">
              <v-list-item-title @click="goToPage('/User')"><v-icon class="mr-2">mdi-card-account-details-outline</v-icon>User Management</v-list-item-title>
            </v-list-item>
            <v-list-item link :class="$route.name.includes('Audit') ? 'page-active' : ''">
              <v-list-item-title @click="goToPage('/Audit')"><v-icon class="mr-2">mdi-clipboard-search-outline</v-icon>Audit Trail</v-list-item-title>
            </v-list-item>
            <v-list-item link class="error--text">
              <v-list-item-title @click="goToPage('/')"><v-icon class="mr-2 red--text">mdi-logout-variant</v-icon>Logout</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>

    </v-app-bar>
  </div>
</template>

<script>
export default {
    data () {
      return {

      }
    },

    mounted(){
      
    },

    methods: {

      loading() {
        return this.$store.getters.loading
      },

      signOut() {
        this.$store.dispatch('signOut')
      },

      displayMap(){
        // window.location.href = 'https://atania.kleostechnologies.com/dss/'
        window.open(
          'https://atania.kleostechnologies.com/dss/',
          '_blank' // <- This is what makes it open in a new window.
        )
      }
      
    },

    computed: {
      user() {
          return this.$store.getters.user
      },
    },
}
</script>

<style lang="scss" scoped>
@import '~scss/main';
.page-active {
  background-color: #f2f5f3 !important;
}

</style>

