<template>
  <v-app id="app">
    <!-- main header -->
    <main-header v-if="$route.meta.showMainHeader == true"></main-header>

    <!-- app header -->
    <app-header v-if="$route.meta.showAppHeader == true"></app-header>
    
    <!-- Left Sidebar -->
    <sidebar v-if="$route.meta.showLeftSidebar == true && $vuetify.breakpoint.mdAndUp"></sidebar>
    
    <!-- content -->
    <v-main class="base-background">
      <v-container class="">
        <router-view :key="$route.path"></router-view>
      </v-container>
    </v-main>

    <!-- app footer -->
    <app-footer v-if="$route.meta.showFooter == true"></app-footer>
  </v-app>
</template>

<script>
import AppHeader from "@/components/AppHeader";
import AppFooter from "@/components/AppFooter";
import LeftSidebar from "@/components/Sidebar";
import MainHeader from "@/components/MainHeader";
export default {
  name: "App",
  components: {
    "app-header": AppHeader,
    "app-footer": AppFooter,
    "sidebar": LeftSidebar,
    "main-header": MainHeader,
  },

  data: () => ({}),

  methods: {},
  watch: {},
};
</script>

<style lang="scss" scoped>
@import "~scss/main";

.base-background{
  background-color: #ededed;
}
</style>
